import * as React from 'react';
//theme
import Theme from '../context/theme';
// components
import { ChakraProvider } from '@chakra-ui/react';

const Layout = ({ children }: any) => {
  return (
    <ChakraProvider resetCSS theme={Theme}>
      <div></div>
      {children}
    </ChakraProvider>
  );
};

export default Layout;
